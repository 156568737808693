import * as React from 'react';

export const IllustrationSVG = (props) => {
    return (
        <svg
            id="bebdbc02-5f56-4bd5-9ad9-bce5f07a2c3e"
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 933.44272 783.89357"
        >
            <title>lists</title>
            <path
                d="M627.33188,841.14167c0,15.28692-94.57823,27.67192-211.44706,27.7519h-1.06254c-57.04625,0-108.84836-2.93629-147.02008-7.7006-1.6338-.21709-3.2448-.42273-4.82149-.63981-6.48953-.8569-12.55633-1.78234-18.1433-2.74209-26.70079-4.65009-42.52473-10.41982-42.52473-16.6694,0-5.06144,10.35127-9.79144,28.43745-13.8817,9.55149-2.15939,21.25094-4.1245,34.73271-5.86116q3.719-.497,7.63206-.93688c37.60053-4.39869,87.26605-7.07223,141.70738-7.07223,10.57979,0,20.98817.10286,31.15667.29707C548.56622,815.65188,627.33188,827.19142,627.33188,841.14167Z"
                transform="translate(-73.77864 -85)"
                fill="#3f3d56"
            />
            <rect
                x="617.70498"
                y="416.06562"
                width="314.75413"
                height="161.31149"
                fill="#f2f2f2"
            />
            <rect
                x="226.22953"
                y="0.98361"
                width="314.75413"
                height="161.31149"
                fill="#f2f2f2"
            />
            <path
                d="M615.74591,248.27871H299.02457V85H615.74591Zm-314.75413-1.96722H613.7787V86.96721H300.99178Z"
                transform="translate(-73.77864 -85)"
                fill="#659650"
            />
            <rect
                x="617.70498"
                y="0.98361"
                width="314.75413"
                height="161.31149"
                fill="#f2f2f2"
            />
            <rect
                x="226.22953"
                y="208.52461"
                width="314.75413"
                height="161.31149"
                fill="#f2f2f2"
            />
            <rect
                x="617.70498"
                y="208.52461"
                width="314.75413"
                height="161.31149"
                fill="#f2f2f2"
            />
            <rect
                x="226.22953"
                y="416.06562"
                width="314.75413"
                height="161.31149"
                fill="#f2f2f2"
            />
            <circle cx="40.32787" cy="81.63935" r="40.32787" fill="#f2f2f2" />
            <path
                d="M227.22128,126.31148a40.32788,40.32788,0,1,0,40.32787,40.32787A40.32787,40.32787,0,0,0,227.22128,126.31148Zm17.47631,55.31058-3.47721,3.47721-14.4909-14.49091-14.49091,14.49091-3.47721-3.47721,14.49091-14.4909L206.79415,150.673l3.4772-3.4772L226.72948,163.654l16.45812-16.45812,3.4772,3.4772-16.45812,16.45813Z"
                transform="translate(-73.77864 -85)"
                fill="#f2f2f2"
            />
            <polygon
                points="36.256 100.783 17.71 76.237 21.634 73.271 36.531 92.988 78.311 45.105 82.017 48.338 36.256 100.783"
                fill="#659650"
            />
            <path
                d="M580.49352,662.04422q-19.18523-3.25031-36.90106-5.58273l4.669-9.05252c-1.57439-.54682-8.49144,4.63729-8.49144,4.63729l6.1273-26.27728c-7.9182.9549-11.9441,27.78561-11.9441,27.78561l-8.84669-9.07128,4.283,10.1985a816.82143,816.82143,0,0,0-93.62328-5.89217l3.99619-7.748c-1.57439-.54682-8.49144,4.63729-8.49144,4.63729l6.12729-26.27729c-7.91819.95491-11.9441,27.78562-11.9441,27.78562l-8.84668-9.07129,4.53634,10.80189a525.9061,525.9061,0,0,0-53.82008,3.65579c4.042-12.07,17.7728-23.54953,17.7728-23.54953-10.48615,3.11875-15.98671,8.34487-18.86091,13.21713a211.63887,211.63887,0,0,1,12.23271-73.124s-21.67451,47.16568-18.90888,78.82526l.33079,5.62183c-18.3751,2.63107-25.60407,8.81279-25.60407,8.81279Z"
                transform="translate(-73.77864 -85)"
                fill="#3f3d56"
            />
            <circle cx="355.70497" cy="255.58758" r="34.78687" fill="#ff6584" />
            <polygon
                points="728.996 475.767 724.918 472.504 747.757 452.112 805.67 452.112 805.67 470.057 768.149 495.343 728.996 475.767"
                fill="#3f3d56"
            />
            <polygon
                points="816.229 463.551 816.229 452.52 808.54 452.52 808.54 455.221 805.67 452.112 786.909 472.504 779.568 477.398 768.186 560.56 785.352 577.726 825.246 577.726 825.246 473.32 816.229 463.551"
                fill="#ccc"
            />
            <rect
                x="724.91811"
                y="472.50395"
                width="61.99121"
                height="105.22193"
                fill="#f2f2f2"
            />
            <rect
                x="748.98049"
                y="552.03216"
                width="11.41943"
                height="25.69373"
                fill="#ccc"
            />
            <polygon
                points="736.745 559.373 736.745 555.295 731.851 555.295 731.851 559.373 736.338 559.373 736.745 559.373"
                fill="#3f3d56"
            />
            <polygon
                points="736.338 562.636 731.851 562.636 731.851 566.714 736.745 566.714 736.745 562.636 736.338 562.636"
                fill="#3f3d56"
            />
            <rect
                x="740.00807"
                y="555.29485"
                width="4.89404"
                height="4.07837"
                fill="#3f3d56"
            />
            <rect
                x="740.00807"
                y="562.63592"
                width="4.89404"
                height="4.07837"
                fill="#3f3d56"
            />
            <polygon
                points="770.188 559.373 770.188 555.295 765.294 555.295 765.294 559.373 769.78 559.373 770.188 559.373"
                fill="#3f3d56"
            />
            <polygon
                points="769.78 562.636 765.294 562.636 765.294 566.714 770.188 566.714 770.188 562.636 769.78 562.636"
                fill="#3f3d56"
            />
            <rect
                x="773.4507"
                y="555.29485"
                width="4.89404"
                height="4.07837"
                fill="#3f3d56"
            />
            <rect
                x="773.4507"
                y="562.63592"
                width="4.89404"
                height="4.07837"
                fill="#3f3d56"
            />
            <polygon
                points="804.446 477.806 804.446 473.727 799.552 473.727 799.552 477.806 804.038 477.806 804.446 477.806"
                fill="#3f3d56"
            />
            <polygon
                points="804.038 481.069 799.552 481.069 799.552 485.147 804.446 485.147 804.446 481.069 804.038 481.069"
                fill="#3f3d56"
            />
            <rect
                x="807.709"
                y="473.72747"
                width="4.89404"
                height="4.07837"
                fill="#3f3d56"
            />
            <rect
                x="807.709"
                y="481.06853"
                width="4.89404"
                height="4.07837"
                fill="#3f3d56"
            />
            <polygon
                points="804.446 500.645 804.446 496.566 799.552 496.566 799.552 500.645 804.038 500.645 804.446 500.645"
                fill="#3f3d56"
            />
            <polygon
                points="804.038 503.907 799.552 503.907 799.552 507.986 804.446 507.986 804.446 503.907 804.038 503.907"
                fill="#3f3d56"
            />
            <rect
                x="807.709"
                y="496.56633"
                width="4.89404"
                height="4.07837"
                fill="#3f3d56"
            />
            <rect
                x="807.709"
                y="503.9074"
                width="4.89404"
                height="4.07837"
                fill="#3f3d56"
            />
            <polygon
                points="804.446 523.484 804.446 519.405 799.552 519.405 799.552 523.484 804.038 523.484 804.446 523.484"
                fill="#3f3d56"
            />
            <polygon
                points="804.038 526.746 799.552 526.746 799.552 530.825 804.446 530.825 804.446 526.746 804.038 526.746"
                fill="#3f3d56"
            />
            <rect
                x="807.709"
                y="519.4052"
                width="4.89404"
                height="4.07837"
                fill="#3f3d56"
            />
            <rect
                x="807.709"
                y="526.74627"
                width="4.89404"
                height="4.07837"
                fill="#3f3d56"
            />
            <polygon
                points="804.446 546.322 804.446 542.244 799.552 542.244 799.552 546.322 804.038 546.322 804.446 546.322"
                fill="#3f3d56"
            />
            <polygon
                points="804.038 549.585 799.552 549.585 799.552 553.664 804.446 553.664 804.446 549.585 804.038 549.585"
                fill="#3f3d56"
            />
            <rect
                x="807.709"
                y="542.24407"
                width="4.89404"
                height="4.07837"
                fill="#3f3d56"
            />
            <rect
                x="807.709"
                y="549.58514"
                width="4.89404"
                height="4.07837"
                fill="#3f3d56"
            />
            <rect
                x="731.85133"
                y="477.80583"
                width="47.30908"
                height="9.78809"
                fill="#3f3d56"
            />
            <rect
                x="731.85133"
                y="497.38201"
                width="47.30908"
                height="9.78809"
                fill="#3f3d56"
            />
            <rect
                x="731.85133"
                y="516.95818"
                width="47.30908"
                height="9.78809"
                fill="#3f3d56"
            />
            <rect
                x="731.85133"
                y="536.53435"
                width="47.30908"
                height="9.78809"
                fill="#3f3d56"
            />
            <polygon
                points="355.902 469.793 397.421 469.793 397.421 459.771 420.328 459.771 420.328 469.793 463.279 469.793 463.279 514.176 420.328 514.176 420.328 570.012 397.421 570.012 397.421 514.176 355.902 514.176 334.426 489.837 355.902 469.793"
                fill="#3f3d56"
            />
            <path
                d="M551.81148,454.8361h-1.96722a96.39345,96.39345,0,1,0-192.7869,0h-1.96721a98.36067,98.36067,0,0,1,196.72133,0Z"
                transform="translate(-73.77864 -85)"
                fill="#3f3d56"
            />
            <rect
                x="404.17666"
                y="355.98722"
                width="2.51384"
                height="99.29687"
                transform="matrix(1, -0.00188, 0.00188, 1, -74.53899, -84.23861)"
                fill="#3f3d56"
            />
            <path
                d="M429.821,368.59564c.22517,34.72128-24.15028,63.0277-24.15028,63.0277s-24.74052-27.98789-24.96569-62.70917,24.15027-63.0277,24.15027-63.0277S429.59582,333.87436,429.821,368.59564Z"
                transform="translate(-73.77864 -85)"
                fill="#659650"
            />
            <path
                d="M429.821,368.59564c.22517,34.72128-24.15028,63.0277-24.15028,63.0277s-24.74052-27.98789-24.96569-62.70917,24.15027-63.0277,24.15027-63.0277S429.59582,333.87436,429.821,368.59564Z"
                transform="translate(-73.77864 -85)"
                opacity="0.1"
            />
            <rect
                x="371.49674"
                y="356.04854"
                width="2.51384"
                height="99.29687"
                transform="matrix(1, -0.00188, 0.00188, 1, -74.53916, -84.29992)"
                fill="#3f3d56"
            />
            <path
                d="M397.14106,368.657c.22518,34.72128-24.15027,63.0277-24.15027,63.0277s-24.74052-27.9879-24.96569-62.70918,24.15027-63.0277,24.15027-63.0277S396.91589,333.93568,397.14106,368.657Z"
                transform="translate(-73.77864 -85)"
                fill="#659650"
            />
            <rect
                x="465.54425"
                y="291.88024"
                width="1.99013"
                height="78.61032"
                fill="#3f3d56"
            />
            <path
                d="M559.67971,386.89823c.12669,27.48807-19.21262,49.86116-19.21262,49.86116s-19.54472-22.19388-19.67141-49.68195,19.21263-49.86116,19.21263-49.86116S559.553,359.41015,559.67971,386.89823Z"
                transform="translate(-73.77864 -85)"
                fill="#659650"
            />
            <circle cx="770.60875" cy="291.63937" r="68.40773" fill="#3f3d56" />
            <polygon
                points="777.45 266.171 777.45 294.807 735.823 294.807 728.132 304.034 729.01 294.807 725.269 294.807 725.269 266.171 777.45 266.171"
                fill="#659650"
            />
            <rect
                x="758.67717"
                y="276.03476"
                width="18.77235"
                height="18.77235"
                opacity="0.1"
            />
            <polygon
                points="762.813 280.171 814.994 280.171 814.994 308.807 811.252 308.807 812.131 318.034 804.441 308.807 762.813 308.807 762.813 280.171"
                fill="#659650"
            />
            <ellipse
                cx="728.4505"
                cy="307.53414"
                rx="11.45432"
                ry="1.11749"
                opacity="0.1"
            />
            <ellipse
                cx="812.13065"
                cy="322.48838"
                rx="13.04519"
                ry="1.2727"
                opacity="0.1"
            />
            <path
                d="M883.2215,106.0316H838.018a9.60936,9.60936,0,0,0-9.37409,7.496L805.80347,214.83763a7.76645,7.76645,0,0,0,7.1941,9.46513l46.46951,2.28955a10.2603,10.2603,0,0,0,10.60137-8.42156l12.95649-71.62734.9639.2754,2.47911-13.57606-.99221-.24805,3.61661-19.99376A5.91643,5.91643,0,0,0,883.2215,106.0316Z"
                transform="translate(-73.77864 -85)"
                fill="#3f3d56"
            />
            <path
                d="M843.969,108.21557h-5.27661a6.64081,6.64081,0,0,0-6.48818,5.22521l-21.92367,100.4835a4.11488,4.11488,0,0,0,3.75119,4.98323l46.31918,3.03571a5.32916,5.32916,0,0,0,5.59329-4.37305l18.70761-103.85948a4.66768,4.66768,0,0,0-4.59375-5.49512h-5.00569a2.20288,2.20288,0,0,0-2.12714,1.6302h0a1.94476,1.94476,0,0,1-1.87789,1.43917h-23.7638a3.32434,3.32434,0,0,1-3.31454-3.06937Z"
                transform="translate(-73.77864 -85)"
                fill="#659650"
            />
            <circle cx="389.94677" cy="81.78849" r="65.46092" fill="#659650" />
            <path
                d="M520.72517,207.888c-12.56848.00078-40.32306-12.90833-65.00687-28.02709-30.63032-18.761-60.3785-43.28289-55.17787-51.77425,1.47-2.39963,5.11342-3.02717,11.14053-1.92079l-.43212,2.35291c-4.66729-.85879-7.82566-.559-8.66849.81675-1.2294,2.00722,1.48128,7.83423,12.77519,18.16852,10.29225,9.41867,25.07038,20.18508,41.61241,30.31694,16.54164,10.13186,32.84776,18.40443,45.91376,23.294,14.33783,5.36608,20.76007,5.13172,21.9887,3.12606.844-1.37811-.32857-4.33132-3.21755-8.10362l1.899-1.45441c3.73063,4.87167,4.82923,8.40649,3.35847,10.8069C525.90049,207.139,523.75351,207.888,520.72517,207.888Z"
                transform="translate(-73.77864 -85)"
                fill="#3f3d56"
            />
            <circle cx="381.86671" cy="48.14092" r="13.38973" fill="#fff" />
            <circle cx="385.74494" cy="121.54293" r="8.92649" fill="#fff" />
            <circle cx="416.48373" cy="90.94044" r="8.92649" fill="#fff" />
            <path
                d="M1007.22136,663.36071H690.5V500.082h316.72134ZM692.46723,661.3935h312.78692V502.04922H692.46723Z"
                transform="translate(-73.77864 -85)"
                fill="#659650"
            />
            <path
                d="M1005.25415,456.80332H688.5328V293.52461h316.72135ZM690.5,454.8361h312.78691V295.49182H690.5Z"
                transform="translate(-73.77864 -85)"
                fill="#659650"
            />
            <path
                d="M298.18879,805.30317c5.36982,19.85008,23.76267,32.14374,23.76267,32.14374s9.68665-19.88969,4.31683-39.73977-23.76267-32.14374-23.76267-32.14374S292.819,785.45308,298.18879,805.30317Z"
                transform="translate(-73.77864 -85)"
                fill="#3f3d56"
            />
            <path
                d="M306.07936,801.03717c14.73487,14.3438,16.53462,36.39356,16.53462,36.39356s-22.09018-1.20614-36.825-15.54994-16.53463-36.39355-16.53463-36.39355S291.34449,786.69337,306.07936,801.03717Z"
                transform="translate(-73.77864 -85)"
                fill="#659650"
            />
            <path
                d="M357.556,528.63387l-15.70335,1.74482s-6.97927,31.4067,0,73.28231S357.556,687.41221,357.556,687.41221s6.97927,136.09571,10.4689,137.84053,34.89634,12.21372,36.64115,1.74482,1.74482-249.50882,1.74482-249.50882l24.42744,115.15792s3.48963,144.8198,10.4689,144.8198,29.66188-1.74482,29.66188-13.95854S476.20354,650.771,476.20354,650.771s-1.74481-118.64754-8.72408-122.13718S357.556,528.63387,357.556,528.63387Z"
                transform="translate(-73.77864 -85)"
                fill="#2f2e41"
            />
            <path
                d="M371.51453,820.01829s-15.70335-1.74482-19.193,0-8.72409,13.95853,0,20.9378,31.4067,17.44817,38.386,17.44817,13.95853-3.48963,13.95853-3.48963l-3.48963-29.66189Z"
                transform="translate(-73.77864 -85)"
                fill="#2f2e41"
            />
            <path
                d="M441.30721,828.74237l1.74481,24.42744s22.68262,3.48963,24.42744,0,19.193-12.21372,20.9378-13.95854,3.48964-20.9378-6.97926-22.68262-13.95854,3.48964-13.95854,3.48964Z"
                transform="translate(-73.77864 -85)"
                fill="#2f2e41"
            />
            <path
                d="M481.85415,321.01532s38.147-24.53087,37.26414-41.38406-23.87951-69.9918-23.87951-69.9918-17.99016-37.4032-25.14209-22.11729,9.39989,31.55733,9.39989,31.55733l10.6833,52.12683-29.03429,27.69849Z"
                transform="translate(-73.77864 -85)"
                fill="#a0616a"
            />
            <circle cx="313.43924" cy="169.69762" r="31.4067" fill="#a0616a" />
            <path
                d="M371.51453,273.89061l5.23445,33.15152,38.386-6.97927s-1.74481-33.15152-1.74481-36.64115S371.51453,273.89061,371.51453,273.89061Z"
                transform="translate(-73.77864 -85)"
                fill="#a0616a"
            />
            <path
                d="M369.76972,307.04213s43.62042-10.4689,48.85487-6.97927,31.4067,6.97927,31.4067,6.97927l29.66189,45.36524-8.72409,57.57895s-1.74481,92.4753,1.74482,108.17865,22.68262,17.44817-17.44817,20.9378-47.11-1.74481-75.02712,5.23445-50.59969-3.48963-43.62042-10.4689,19.193-118.64754,5.23445-129.11645-19.193-64.55822-19.193-64.55822S369.76972,315.76621,369.76972,307.04213Z"
                transform="translate(-73.77864 -85)"
                fill="#3f3d56"
            />
            <path
                d="M444.79684,312.27658l5.23445-5.23445,10.4689-10.4689s34.02393,14.83094,32.27912,20.06539-13.08613,35.76875-13.08613,35.76875l-15.70335,5.23445Z"
                transform="translate(-73.77864 -85)"
                fill="#3f3d56"
            />
            <path
                d="M398.17835,216.64808l-.32186-2.51594a19.77829,19.77829,0,0,0-6.2856.9877,10.32665,10.32665,0,0,1,.388-4.52107,20.103,20.103,0,0,0-5.81783,2.52934l-1.26884-4.59162-17.4426,8.59632c-6.26123,3.08575-12.86249,6.47669-16.44172,12.46952-3.99031,6.68112-3.29591,15.26752-.56816,22.55582s7.26036,13.73814,10.89358,20.62a51.51652,51.51652,0,0,0,4.95064,8.426c6.58389,8.20751,19.3997,9.86569,28.68263,4.91211,3.42591-1.82815,6.53859-4.56058,7.948-8.17894.84275-2.16359,1.03547-4.51792,1.58763-6.77324,1.14826-4.69012,2.48122-14.37168,6.29067-17.3388,1.67631-1.30565,4.99988,3.02263,6.389,1.41483,3.5699-4.13179,2.82068-10.5038.60359-15.494a113.05265,113.05265,0,0,0-7.4426-14.51667C409.125,223.35867,398.13974,216.3463,398.17835,216.64808Z"
                transform="translate(-73.77864 -85)"
                fill="#2f2e41"
            />
            <path
                d="M294.74259,382.06926S252.867,422.2,258.10144,443.13785s47.11005,82.00639,47.11005,82.00639,40.13079,20.9378,45.36524,0-27.91707-15.70335-27.91707-15.70335l-26.17225-62.81341,29.66188-41.87561Z"
                transform="translate(-73.77864 -85)"
                fill="#a0616a"
            />
            <path
                d="M343.59746,343.68328l-20.9378-3.48963s-15.70335,17.44817-20.9378,24.42744-15.70335,24.42743-15.70335,24.42743l36.64115,26.17226,27.91707-29.66189Z"
                transform="translate(-73.77864 -85)"
                fill="#3f3d56"
            />
        </svg>
    );
};
