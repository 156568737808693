import asyncComponent from "../../util/asyncComponent";
import { ADMIN } from "../../constants/LookupConst";
// import { PlayCircleOutlined } from "@ant-design/icons";

// right now /user/login and /user/signup use the same scene, but the scene component is remounting when navigating between these two routes
// because each route has a different instance of the scene. It would be nice if we could change this in order to get a more single page app feel
// essentially just load one async component for both /user/login & /user/signup

export const anonymousRoutes = [
    {
        path: "/user",
        component: asyncComponent(() => import("../Layout/AnonymousUserLayout")),
        isLayout: true,
        showInMenu: false,
    },
    {
        path: "/user/update/:updateid",
        showInMenu: false,
        component: asyncComponent(() => import("../../scenes/Anonymous/ViewUpdate")),
    },
    {
        path: "/user/view/:updateid",
        showInMenu: false,
        component: asyncComponent(() => import("../../scenes/Anonymous/ViewLink")),
    },
    {
        path: "/user/information/:updateid",
        showInMenu: false,
        component: asyncComponent(() => import("../../scenes/Anonymous/ViewInformation")),
    },
    {
        path: "/user/topic/:slug",
        showInMenu: false,
        component: asyncComponent(() => import("../../scenes/Anonymous/Topic")),
    },
    {
        path: "/user/issue-desk/:slug",
        showInMenu: false,
        component: asyncComponent(() => import("../../scenes/Anonymous/IssueDesk")),
    },
    {
        path: "/user/login",
        component: asyncComponent(() => import("../../scenes/Auth")),
        showInMenu: false,
    },
    {
        path: "/user/signup",
        component: asyncComponent(() => import("../../scenes/Auth")),
        showInMenu: false,
    },
    {
        path: "/user/reset-password",
        component: asyncComponent(() => import("../../scenes/Auth")),
        showInMenu: false,
    },
    {
        path: "/user/reset-password-success",
        component: asyncComponent(() => import("../../scenes/Auth")),
        showInMenu: false,
    },
    {
        path: "/user/trouble-signing-in",
        component: asyncComponent(() => import("../../scenes/Auth")),
        showInMenu: false,
    },
    {
        path: "/user/trouble-signing-in-success",
        component: asyncComponent(() => import("../../scenes/Auth")),
        showInMenu: false,
    },
];

export const authenticatedRoutes = [
    {
        path: "/",
        exact: true,
        component: asyncComponent(() => import("../Layout/AuthenticatedUserLayout")),
        isLayout: true,
        showInMenu: false,
    },
    {
        path: "/admin/dashboard",
        role: [ADMIN],
        showInMenu: false,
        component: asyncComponent(() => import("../../scenes/Admin/Dashboard")),
    },
    {
        path: "/admin/client/new",
        role: [ADMIN],
        showInMenu: false,
        component: asyncComponent(() => import("../../scenes/Admin/Client/NewClient")),
    },
    {
        path: "/admin/client/:clientId",
        role: [ADMIN],
        showInMenu: false,
        component: asyncComponent(() => import("../../scenes/Admin/Client")),
    },
    {
        path: "/admin/client/:clientId/edit-email",
        role: [ADMIN],
        showInMenu: false,
        component: asyncComponent(() => import("../../scenes/Admin/EditEmail")),
    },
    {
        path: "/admin/client/:clientId/edit-client",
        role: [ADMIN],
        showInMenu: false,
        component: asyncComponent(() => import("../../scenes/Admin/Client/EditClient")),
    },
    {
        path: "/admin/updates",
        role: [ADMIN],
        showInMenu: false,
        component: asyncComponent(() => import("../../scenes/Admin/Updates/index")),
    },
    {
        path: "/admin/updates/edit-update",
        role: [ADMIN],
        showInMenu: false,
        component: asyncComponent(() => import("../../scenes/Admin/Updates/NewUpdate")),
    },
    {
        path: "/admin/information",
        role: [ADMIN],
        showInMenu: false,
        component: asyncComponent(() => import("../../scenes/Admin/Information/index")),
    },
    {
        path: "/admin/information/edit-information-point",
        role: [ADMIN],
        showInMenu: false,
        component: asyncComponent(() => import("../../scenes/Admin/Information/NewInformation")),
    },
    {
        path: "/admin/compendium",
        role: [ADMIN],
        showInMenu: false,
        component: asyncComponent(() => import("../../scenes/Admin/Compendium/index")),
    },
    {
        path: "/admin/compendium/edit-compendium",
        role: [ADMIN],
        showInMenu: false,
        component: asyncComponent(() => import("../../scenes/Admin/Compendium/EditCompendium")),
    },
    {
        path: "/admin/pipelines",
        role: [ADMIN],
        showInMenu: false,
        component: asyncComponent(() => import("../../scenes/Admin/Pipelines/index")),
    },
    {
        path: "/admin/pipelines/edit-pipeline",
        role: [ADMIN],
        showInMenu: false,
        component: asyncComponent(() => import("../../scenes/Admin/Pipelines/NewPipeline")),
    },
    {
        path: "/admin/qa",
        role: [ADMIN],
        showInMenu: false,
        component: asyncComponent(() => import("../../scenes/Admin/Quality/index")),
    },
    {
        path: "/admin/analytics",
        role: [ADMIN],
        showInMenu: false,
        component: asyncComponent(() => import("../../scenes/Admin/Analytics/index")),
    },
];

export const routers = [...anonymousRoutes, ...authenticatedRoutes];
