// import React, { Component } from 'react'
// import { hot } from 'react-hot-loader'
// import {
//   BrowserRouter as Router,
//   Route,
//   Switch,
//   Redirect,
// } from 'react-router-dom'

// import styled from 'styled-components'

// // Redux
// import { connect } from 'react-redux'
// import { setupDataOnLoad } from './redux/actions'

// // Views
// import Landing from './views/Landing/index'

// // Components
// import Overlays from './components/Overlays/index'
// import Header from './components/Header'
// import AppHeader from './components/AppHeader'
// // import config from './config/config'

// class App extends Component {
//   constructor(props) {
//     super(props)
//     this.state = {}
//   }

//   componentDidMount() {
//     this.props.setupDataOnLoad()
//   }

//   render() {
//     if (this.props.userLoggedIn) {
//       return (
//         <Router>
//           <>
//             <Overlays />

//             <Switch>
//               <Route
//                 exact
//                 path="/coins"
//                 render={(routeProps) => (
//                   <AppContainer>
//                     <AppHeader />
//                     {/* <AllCoins {...routeProps} /> */}
//                   </AppContainer>
//                 )}
//               />
//               <Redirect to="/coins" />
//             </Switch>
//           </>
//         </Router>
//       )
//     } else {
//       return (
//         <Router>
//           <>
//             <Overlays />

//             <Switch>
//               <Route
//                 exact
//                 path="/landing"
//                 render={(routeProps) => (
//                   <>
//                     <Header />
//                     <Landing {...routeProps} />
//                   </>
//                 )}
//               />

//               <Redirect to="/landing" />
//             </Switch>
//           </>
//         </Router>
//       )
//     }
//   }
// }

// const mapStateToProps = (state) => {
//   let stateBuilder = {}

//   if (state.userLoggedIn) stateBuilder.userLoggedIn = state.userLoggedIn

//   return stateBuilder
// }

// const mapDispatchToProps = (dispatch) => {
//   return {
//     // Returns a promise
//     setupDataOnLoad: () => dispatch(setupDataOnLoad()),
//   }
// }

// const ConnectedApp = connect(mapStateToProps, mapDispatchToProps)(App)

// export default hot(module)(ConnectedApp)

// const AppContainer = styled.div`
//   width: 100%;
//   display: flex;
// `
import * as React from 'react';
import Router from './components/Router';
import { ToastContainer } from 'react-toastify';
import Header from './components/Header';

import 'react-toastify/dist/ReactToastify.css';

class App extends React.Component {
    render() {
        return (
            <React.Fragment>
                <ToastContainer />
                <div className="flex flex-col min-h-screen">
                    <Header />
                    <Router />
                </div>
            </React.Fragment>
        );
    }
}

export default App;
