import * as React from 'react';

export const CandidatesSVG = (props) => {
    return (
        <svg viewBox="0 0 32 18" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M8.12 3.06C8.12 1.373 6.767 0 5.1 0 3.437 0 2.015 1.373 2.015 3.06c0 1.688 1.422 3.06 3.087 3.06 1.665 0 3.02-1.372 3.02-3.06zM10.134 11.22c0-2.812-2.258-5.1-5.034-5.1-2.775 0-5.1 2.288-5.1 5.1 0 .564.45 1.02 1.007 1.02h8.12c.557 0 1.007-.456 1.007-1.02zM29.987 3.06C29.987 1.373 28.565 0 26.9 0c-1.665 0-3.02 1.373-3.02 3.06 0 1.688 1.355 3.06 3.02 3.06s3.087-1.372 3.087-3.06z" />
            <path d="M26.9 6.12c-2.776 0-5.034 2.288-5.034 5.1 0 .564.45 1.02 1.007 1.02h8.12c.557 0 1.007-.456 1.007-1.02 0-2.812-2.325-5.1-5.1-5.1zM19.645 5.76c0-2.25-1.806-4.08-4.027-4.08-2.22 0-4.026 1.83-4.026 4.08s1.806 4.08 4.026 4.08c2.221 0 4.027-1.83 4.027-4.08zM10.586 18h10.066c.557 0 1.007-.456 1.007-1.02v-1.02c0-3.375-2.71-6.12-6.04-6.12s-6.04 2.745-6.04 6.12v1.02c0 .564.45 1.02 1.007 1.02z" />
        </svg>
    );
};
